import { AppInfo } from '$/app/services';
import { environment } from '$/environments/environment';
import { buildNumber, version } from '$shared';
import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
    selector: 'alc-version-marker',
    imports: [CommonModule],
    templateUrl: './version-marker.component.html'
})
export class AlcVersionMarkerComponent {
  protected readonly class = input('');

  protected get versionString(): string {
    const attributes = [
      buildNumber,
      AppInfo.deviceInfo?.platform,
      environment.production ? '' : environment.name
    ].filter(Boolean);

    return `Alcomy v${version} (${attributes.join(', ')})`;
  }

  protected get buildTimestamp(): string {
    return environment.production
      ? ''
      : DateTime.fromISO(environment.buildTimestamp).toLocaleString(
          DateTime.DATETIME_SHORT
        );
  }
}
